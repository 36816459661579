<template>
  <AudioCard
    v-if="url"
    :player="{ title: title, fileUrl: url }"
  />
</template>

<script>
import { AudioCard } from '@seliaco/red-panda'
export default {
  name: 'AudioPlayer',
  components: { AudioCard },
  data () {
    return {
      title: null,
      url: null
    }
  },
  created () {
    window.addEventListener('message', (event) => {
      if (event.data.url) {
        this.url = event.data.url
        this.title = event.data.title
      }
    })
  }
}
</script>
